import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Form from '../Form';

import './index.css';

class Index extends Component {
  render() {
    return (
      <section id="Contacts" className="Contacts">
        <div className="block-wrapper">
          <h1 className="block-header">Contacts</h1>
          <Form className="Contacts-form"/>
          <p className="Contacts-link-before-text">... or on Web services</p>
          <div className="Contacts-link-box">
            <a href="https://www.facebook.com/takato.ezaki" className="Contacts-link">
              <FontAwesomeIcon icon={['fab', 'facebook']}/>
            </a>
            <a href="https://twitter.com/takato_ezaki" className="Contacts-link">
              <FontAwesomeIcon icon={['fab', 'twitter']}/>
            </a>
            <a href="https://github.com/ezaki" className="Contacts-link">
              <FontAwesomeIcon icon={['fab', 'github']}/>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default Index;
