import React, { Component } from 'react';
import './index.css';

const g = -50;

class Index extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.canvas = null;
    this.ctx = null;
    this.t0 = 0;
    this.dots= [];

    this.state = {
      windowWidth: 0,
      windowHeight: 0,
    }
  }

  draw() {
    const time = (new Date()).getTime() * 0.0025;
    const dt = time - this.t0;
    this.t0 = time;

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.dots = this.dots
      .filter(target => target.opacity > 0.01)
      .map((target) => {
        const result = Object.assign({}, target);
        result.y = target.y + (target.vy * dt + g * dt * dt);
        result.vy = target.vy + g * dt;
        result.x = target.x + target.vx * dt;
        result.vx = target.vx * 0.95
        result.opacity = target.opacity - dt * 0.2;
        return result;
      });

    for (let i = 0; i < this.dots.length; i++) {
      const target = this.dots[i];
      this.ctx.fillStyle = `rgba(${target.red},${target.green},${target.blue},${target.opacity})`;
      this.ctx.beginPath();
      this.ctx.arc(target.x, target.y, target.radius, 0, Math.PI * 2, false);
      this.ctx.fill();
    }

    const red = 16;
    const green = Math.floor(194 + Math.sin(time * 0.5) * 20);
    const blue = Math.floor(205 - Math.sin(time * 0.1) * 20);
    const alpha = 0.3;
    this.ctx.fillStyle = `rgba(${red},${green},${blue},${alpha})`;

    for (let layer = 0; layer < 3; layer++) {
      this.ctx.beginPath();

      let x = time;
      let y = Math.sin(x);
      const yAxis = 0;
      const xAxis = this.canvas.height * 0.9;
      const unit = 150;
      this.ctx.moveTo(0, unit * y + xAxis);

      for (let i = yAxis; i <= this.canvas.width + 10; i += 10) {
        x = time + (-yAxis + i) / unit;
        y = Math.sin((x + layer) * (layer + 1) * 0.1) / 3;
        this.ctx.lineTo(i, unit * y + xAxis);
      }

      this.ctx.lineTo(this.canvas.width, 0);
      this.ctx.lineTo(0, 0);
      this.ctx.closePath();
      this.ctx.fill();
    }

    requestAnimationFrame(this.draw.bind(this));
  }

  clicked(e) {
    const bounding = e.target.getBoundingClientRect();
    for (let i = 0; i < Math.ceil(Math.random() * 3 + 2); i++) {
      this.dots.push({
        x: e.clientX - bounding.left + Math.floor(Math.random() * 20) - 10,
        y: e.clientY - bounding.top + Math.floor(Math.random() * 20) - 10,
        vx: (Math.random() * 300.0) - 150.0,
        vy: (Math.random() * 100.0),
        red: Math.floor(Math.random() * 255),
        green: Math.floor(Math.random() * 255),
        blue: Math.floor( Math.random() * 255),
        radius: Math.random() * 5.0 + 5.0,
        opacity: 1.0
      });
    }
  }

  componentDidMount() {
    this.canvas = this.canvasRef.current;
    this.ctx = this.canvas.getContext('2d');
    this.draw();
  }

  render() {
    return (
      <canvas
        ref={this.canvasRef}
        className="MainBackground"
        width={this.props.width}
        height={this.props.height}
        onClick={this.clicked.bind(this)}
      >
      </canvas>
    );
  }
}

export default Index;
