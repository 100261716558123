import React, { Component } from 'react';

import GreatImg from '../../media/images/great.svg';
import BetterImg from '../../media/images/better.svg';
import OkImg from '../../media/images/ok.svg';

import './index.css';

class Index extends Component {
  render() {
    return (
      <section id="Skill" className="Skill">
        <div className="block-wrapper">
          <h1 className="block-header">Skill</h1>
          <div className="inner-wrapper Skill-block-wrapper">
            <section className="Skill-block">
              <div className="Skill-level-text">
                <h1 className="Skill-level-text-comment">Great</h1>
                <div className="Skill-logo-wrapper">
                  <img className="Skill-logo" src={GreatImg} alt="Great"/>
                </div>
              </div>
              <div className="Skill-list-wrapper">
                <ul className="Skill-list">
                  <li>JavaScript</li>
                  <li>TypeScript</li>
                  <li>Node.js</li>
                  <li>PHP</li>
                  <li>HTML5</li>
                  <li>CSS3</li>
                  <li>AWS</li>
                  <li>Vue.js</li>
                </ul>
              </div>
            </section>
            <section className="Skill-block">
              <div className="Skill-level-text">
                <h1 className="Skill-level-text-comment">Better</h1>
                <div className="Skill-logo-wrapper">
                  <img className="Skill-logo" src={BetterImg} alt="Better"/>
                </div>
              </div>
              <div className="Skill-list-wrapper">
                <ul className="Skill-list">
                  <li>Java</li>
                  <li>Golang</li>
                  <li>Wordpress</li>
                  <li>React</li>
                  <li>Linux</li>
                </ul>
              </div>
            </section>
            <section className="Skill-block">
              <div className="Skill-level-text">
                <h1 className="Skill-level-text-comment">OK</h1>
                <div className="Skill-logo-wrapper">
                  <img className="Skill-logo" src={OkImg} alt="OK"/>
                </div>
              </div>
              <div className="Skill-list-wrapper">
                <ul className="Skill-list">
                  <li>Kotlin</li>
                  <li>Erlang + Elixir</li>
                  <li>SQL</li>
                  <li>jQuery</li>
                  <li>Ansible</li>
                  <li>Adobe Illustrator</li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

export default Index;
