import React, { Component } from 'react';

import Logo from '../../media/images/ezaki_takato_black.svg'

import './index.css';

class Index extends Component {
  render() {
    return (
      <div ref={this.divRef} className="Footer">
        <img className="Footer-logo" src={Logo} alt="Ezaki Takato"/>
      </div>
    );
  }
}

export default Index;
