import React, { Component } from 'react';
import './index.css';

import MainHead from '../MainHead';
import About from '../About';
import Skill from '../Skill';
import Works from '../Works';
import Contacts from '../Contacts';
import Footer from '../Footer';
import Nav from '../Nav';

class Index extends Component {
  render() {
    return (
      <div className="App">
        <MainHead/>
        <About/>
        <Skill/>
        <Works/>
        <Contacts/>
        <Footer/>
        <Nav/>
      </div>
    );
  }
}

export default Index;
