import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css';

class Index extends Component {
  render() {
    return (
      <div className="Nav">
        <div className="Nav-box">
          <AnchorLink className="Nav-link" href="#MainHead">
            <FontAwesomeIcon icon={['fas', 'home']}/>
          </AnchorLink>
          <AnchorLink offset="50" className="Nav-link" href="#About">
            <FontAwesomeIcon icon={['fas', 'info']}/>
          </AnchorLink>
          <AnchorLink offset="50" className="Nav-link" href="#Skill">
            <FontAwesomeIcon icon={['fas', 'file-signature']}/>
          </AnchorLink>
          <AnchorLink offset="50" className="Nav-link" href="#Works">
            <FontAwesomeIcon icon={['fas', 'briefcase']}/>
          </AnchorLink>
          <AnchorLink offset="50" className="Nav-link" href="#Contacts">
            <FontAwesomeIcon icon={['fas', 'envelope']}/>
          </AnchorLink>
        </div>
      </div>
    );
  }
}

export default Index;
