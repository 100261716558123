import React, { Component } from 'react';
import './index.css';

import AboutImage from '../../media/images/about.jpg';

class Index extends Component {
  render() {
    return (
      <section id="About" className="About">
        <div className="block-wrapper">
          <h1 className="block-header">About</h1>
          <div className="inner-wrapper About-wrapper">
            <img className="About-img" src={AboutImage} alt="about"/>
            <section className="About-text">
              <section className="About-text-section">
                <p>I am Takato Ezaki, web system engineer, 29 years old, from Japan.</p>
                <p>My work scope is everything related to the Web.</p>
                <p>I always try to have interesting things around Web technology.</p>
              </section>
              <section className="About-text-section">
                <p>私、 江嵜 駿人 (Ezaki Takato) は現在 29 歳で、日本在住の Web プログラマです。</p>
                <p>Web に関連するあらゆることを領域としています。</p>
                <p>この分野で、常に楽しいことに挑戦したいと考えています。</p>
              </section>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

export default Index;
