import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './index.css';

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputMailAddress: '',
      inputMessage: '',
      sendError: false,
      sending: false,
      sent: false,
    }

    this.botFieldRef = React.createRef();

    this.changeMailAddress = this.changeMailAddress.bind(this);
    this.changeMessage = this.changeMessage.bind(this);
    this.formSend = this.formSend.bind(this);
  }

  changeMailAddress(e) {
    this.setState({
      inputMailAddress: e.target.value,
    });
  }

  changeMessage(e) {
    this.setState({
      inputMessage: e.target.value,
    });
  }

  async formSend(e) {
    e.preventDefault();

    this.setState({
      sendError: false,
      sending: true,
    });

    const params = new URLSearchParams();
    params.append('form-name', 'contact');
    params.append('bot-field', this.botFieldRef.current.value);
    params.append('email', this.state.inputMailAddress);
    params.append('message', this.state.inputMessage);

    try {
      await axios.post('/', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    } catch (e) {
      this.setState({
        sendError: true,
        sending: false,
      });
      return;
    }

    this.setState({
      sending: false,
      sent: true,
    });
  }

  render() {
    return (
      <form className={`Form ${this.props.className}`} name="contact" method="POST" onSubmit={this.formSend}>
        <p style={{display:'none'}}>
          <label>Don’t fill this out if you're human: <input ref={this.botFieldRef} name="bot-field"/></label>
        </p>
        <label className="Form-parts Form-mail-input-label">
          <p className="Form-mail-input-text">mail</p>
          <input
            className="Form-mail-input"
            type="email"
            name="email"
            placeholder="your.mail.address@example.com"
            value={this.state.inputMailAddress}
            onChange={this.changeMailAddress}
          />
        </label>
        <textarea
          className="Form-parts Form-message-input"
          name="message"
          placeholder="Please message here."
          rows="8"
          value={this.state.inputMessage}
          onChange={this.changeMessage}
        />
        {this.state.sendError &&
          <p className="Form-parts Form-send-error">
            <FontAwesomeIcon className="Form-send-error-icon" icon={['fas', 'exclamation-circle']}/>
            <span className="Form-send-error-text">Sorry, something wrong... Please retry later.</span>
          </p>
        }
        <button
          className="Form-parts Form-send-button"
          type="submit"
          disabled={!this.state.inputMailAddress || !this.state.inputMessage}
        >
          Send
        </button>
        {this.state.sending &&
          <div className="Form-sending-wrapper">
            <p className="Form-sending-text">Sending...</p>
            <FontAwesomeIcon className="Form-sending-icon" icon={['fas', 'circle-notch']} spin/>
          </div>
        }
        {this.state.sent &&
          <div className="Form-sending-wrapper">
            <p className="Form-sending-text">Thank you for your message!</p>
            <FontAwesomeIcon className="Form-sending-icon" icon={['fas', 'check']}/>
          </div>
        }
      </form>
    );
  }
}

export default Index;
