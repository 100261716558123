import React, { Component } from 'react';
import './index.css';

import Logo from '../../media/images/ezaki_takato.svg';
import TextCircuit from '../TextCircuit';
import MainBackground from '../MainBackground';

class Index extends Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();

    this.state = {
      width: 0,
      height: 0,
    }
  }

  windowResize() {
    this.setState({
      width: this.divRef.current.clientWidth,
      height: this.divRef.current.clientHeight,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', () => { this.windowResize(); });
    this.windowResize();
  }

  render() {
    return (
      <div id="MainHead" ref={this.divRef} className="MainHead">
        <MainBackground width={this.state.width} height={this.state.height}/>
        <div className="MainHead-logo-wrapper">
          <img className="MainHead-logo" src={Logo} alt="Takato Ezaki"/>
        </div>
        <p className="MainHead-main-text">
          I am ...
          <TextCircuit list={['JS', 'PHP', 'Golang', 'Java', 'CSS3', 'HTML5', 'AWS']}/> Programmer
        </p>
      </div>
    );
  }
}

export default Index;
