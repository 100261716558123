import React, { Component } from 'react';

import './index.css';

class Index extends Component {
  constructor (props) {
    super(props);

    this.state = {
      index: 0,
      action: 'writing',
      text: '',
    };
  }

  animate() {
    let sleepTime = 50;
    const wroteSleepTime = 500;
    let nextTextLength = 0;
    const currentFullText = this.props.list[this.state.index];
    const currentText = this.state.text;

    switch (this.state.action) {
      case 'writing':
        nextTextLength = currentText.length + 1;
        if (currentFullText.length <= nextTextLength) {
          this.setState({
            action: 'deleting',
            text: currentFullText,
          });
          sleepTime = wroteSleepTime;
        }
        else {
          this.setState({
            text: currentFullText.substr(0, nextTextLength)
          });
        }
        break;
      case 'deleting':
        nextTextLength = currentFullText.length - currentText.length + 1;
        if (nextTextLength <= currentFullText.length) {
          this.setState({
            text: currentFullText.substr(nextTextLength, currentFullText.length)
          })
        }
        else {
          const index = this.state.index + 1 < this.props.list.length ? this.state.index + 1 : 0;
          this.setState({
            action: 'writing',
            text: '',
            index,
          })
        }
        break;
      default:
        return;
    }
    setTimeout(this.animate.bind(this), sleepTime);
  }

  componentDidMount() {
    this.animate();
  }

  render() {
    return (
      <span className="TextCircuit">
        {this.state.text}
      </span>
    );
  }
}

export default Index;
