import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faHome,
  faInfo,
  faFileSignature,
  faBriefcase,
  faExclamationCircle,
  faCircleNotch,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faGithub,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faEnvelope,
  faHome,
  faInfo,
  faFileSignature,
  faBriefcase,
  faExclamationCircle,
  faCircleNotch,
  faCheck,
  faFacebook,
  faTwitter,
  faGithub
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// favicon update
document.addEventListener("visibilitychange", () => {
  const newFavicon = document.createElement('link');
  newFavicon.id = 'favicon';
  newFavicon.setAttribute('type', 'image/x-icon');
  newFavicon.setAttribute('rel', 'shortcut icon');
  newFavicon.setAttribute('href', document.hidden ? '/favicon_inactive.ico' : '/favicon.ico');

  document.getElementById('favicon').remove();
  document.getElementById('head').appendChild(newFavicon);
});
