import React, { Component } from 'react';

import UreideaImg from '../../media/images/ureidea_logo.svg';

import './index.css';

class Index extends Component {
  render() {
    return (
      <section id="Works" className="Works">
        <div className="block-wrapper">
          <h1 className="block-header">Works</h1>
          <div className="inner-wrapper">
            <section className="Works-item">
              <div className="Works-img-wrapper">
                <img className="Works-img" src={UreideaImg} alt="Ureidea"/>
              </div>
              <div className="Works-text">
                <h2 className="Works-title">Ureidea</h2>
                <p>
                  Ureidea は、私が経営する Web 課題解決サービスです。
                </p>
                <p>
                  Ureidea では、ホームページ作成をはじめ、<br/>
                  様々な Web アプリケーション開発から CI/CD 環境の構築など、<br/>
                  Web 開発に関する様々な課題を解決します。
                </p>
              </div>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

export default Index;
